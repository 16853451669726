import React, { FC, useMemo, useState } from 'react'
import { selectCurrentZone } from '../../store/global'
import BusinessesSummary from './BusinessSummary/BusinessSummary'
import GridLoader from 'components/GridLoader/GridLoader'
import OffshoreTable from 'components/offshore/OffshoreTable/OffshoreTable'
import TableActionsBars from 'components/verticals/TableActionsBar/TableActionsBar'

import { CurrentZone } from 'utils/types'
import { OffshoreEntityForTable } from './types'

import { useVerticalEntitiesQuery } from 'hooks/useVerticalEntitiesQuery'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import messages from './messages'

import styles from './OffshoreTablePage.module.scss'
import { getUrlPageValues } from '../VerticalPage/common/utils'
import { IOffshoreOverviewData } from './BusinessSummary/utils'
import { useOffshoreOverviewQuery } from 'hooks/useOffshoreOverviewQuery'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'
import { MRT_ColumnDef } from 'material-react-table'
import { ISelectedColumn } from 'components/verticals/VerticalTableBase/types'
import ActiveFilters from 'components/ActiveFilters/ActiveFilters'

import { filtersInitialState } from './OffshoreTablePage.config'
import { useFilterOptionsQuery } from 'hooks/useFilterOptionsQuery'

const csvFileName = 'Offshore'

const OffshoreTablePage: FC = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const [activeFilters, setActiveFilters] = useState<Filter[]>([])
  const [mergedColumns, setMergedColumns] = useState<MRT_ColumnDef<OffshoreEntityForTable>[]>([])
  const [selectedColumns, setSelectedColumns] = useState<ISelectedColumn[]>([])

  const allColumns = useMemo(() => mergedColumns?.flatMap(column => column?.columns ?? []), [mergedColumns]) || []

  const { searchValue, searchValueDebounced, sorting, setSearchValue, setSorting } = useVerticalSearchAndSort()

  // Fetch overview data
  const { data: verticalOverviewData, isLoading: isLoadingOverview } = useOffshoreOverviewQuery<IOffshoreOverviewData>({
    verticalIndices: currentZone?.offshoreIndices,
    currentQueryKey: 'offshore-overview-key',
    searchValue: searchValueDebounced,
    filters: activeFilters,
  })

  // Fetch entities data
  const { verticalEntities, fetchNextPage, isFetching, isLoading } = useVerticalEntitiesQuery<OffshoreEntityForTable>({
    verticalIndices: currentZone?.offshoreIndices,
    currentQueryKey: 'offshore-entities-key',
    searchValue: searchValueDebounced,
    sorting,
    filters: activeFilters,
  })

  // Fetch filter options data
  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.offshoreIndices,
    currentQueryKey: 'offshore-filter-options-key',
    filtersInitialState,
  })

  return (
    <div className={styles.container}>
      <header className='page-header withNavigationHeader'>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <section className={styles.summary}>
            <BusinessesSummary overviewData={verticalOverviewData} />
          </section>
          <div className={cx(styles.content, { [styles.withFilters]: false })}>
            <div className={styles.gridContainer}>
              <TableActionsBars
                countMessages={messages.count}
                displayedCount={verticalOverviewData ? verticalEntities?.length ?? null : null}
                totalCount={verticalOverviewData && verticalEntities?.length ? verticalOverviewData.total : null}
                zoneEndpoint={currentZone?.offshoreIndices}
                csvFileName={csvFileName}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                type={getUrlPageValues()[0]}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                mergedColumns={mergedColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                allColumns={allColumns}
                filtersInitialState={filtersInitialState}
                filterOptions={filterOptions}
              />
              {isLoading ? (
                <GridLoader />
              ) : (
                <>
                  <ActiveFilters activeFilters={activeFilters} setActiveFilters={setActiveFilters} styles={styles} />
                  <section className={styles.grid}>
                    <OffshoreTable
                      isFetching={isFetching}
                      fetchNextPage={fetchNextPage}
                      totalFetched={verticalEntities?.length || 0}
                      totalDBRowCount={verticalEntities?.length ? verticalOverviewData?.total ?? 0 : 0}
                      verticalEntities={verticalEntities}
                      sorting={sorting}
                      setSorting={setSorting}
                      setMergedColumns={setMergedColumns}
                      selectedColumns={selectedColumns}
                      allColumns={allColumns}
                    />
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default OffshoreTablePage
