import React from 'react'
import GenericCard from 'components/common/GenericCard'
import GenericCardHeader from 'components/common/GenericCardHeader'
import GenericText from 'components/common/GenericText'
import GenericLink from 'components/common/GenericLink'
import EntityCardDetails from 'components/EntityCard/EntityCardDetails'
import GenericHeading from 'components/common/GenericHeading'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import moment from 'moment'
import { extractRootDomainNoExt } from '../../containers/NFT/specs'
import { allowedSources, originalSourceToImageMap, SOURCE_GLOBE } from '../BusinessSource/specs'
import { NOT_AVAILABLE } from 'utils/constants'
import styles from './EntityCard.scss'

const markerIcon: string = require('./images/marker.svg')
const cakeIcon: string = require('./images/cake.svg')
const briefcaseIcon: string = require('./images/briefcase.svg')

interface EntityCardProps {
  tin?: string
  name: string
  image: string
  profileUrls: string[]
  location: string
  birthday: string
}

const EntityCard = ({ tin, name, image, profileUrls, location, birthday }: EntityCardProps) => {
  const theme = useTheme()

  const getSourceSrc = (url: string) =>
    allowedSources.includes(extractRootDomainNoExt(url))
      ? originalSourceToImageMap[extractRootDomainNoExt(url)]
      : originalSourceToImageMap[SOURCE_GLOBE]

  return (
    <GenericCard sx={{ gridColumn: '1 / 2' }} className={styles.entityCard}>
      <GenericCardHeader
        sx={{ bgcolor: 'secondary.main' }}
        title={
          <Stack
            direction='row'
            spacing={{ md: 1, xxxl: 2 }}
            alignItems='center'
            sx={{
              p: 0,
              color: 'white',
            }}
          >
            <img src={briefcaseIcon} alt='TIN' className={styles.tinIcon} />
            <GenericText
              sx={{
                fontWeight: 'bold',
                p: 0,
                fontSize: { md: '13px', lg: '14px', xl: '16px', xxl: '20px', xxxl: '30px' },
              }}
            >
              TIN:
            </GenericText>
            <GenericText
              sx={{
                fontSize: { md: '13px', lg: '14px', xl: '16px', xxl: '20px', xxxl: '30px' },
              }}
            >
              {tin || NOT_AVAILABLE}
            </GenericText>
          </Stack>
        }
      />
      <CardContent
        sx={{
          p: { md: '10px', lg: '12px', xl: '12px 16px', xxl: '18px 26px 26px', xxxl: '36px 32px 0px' },
          '&:last-child': {
            paddingBottom: { md: '10px', lg: '12px', xl: '12px', xxl: '26px', xxxl: '20px' },
          },
        }}
      >
        <Stack direction='row'>
          <Avatar
            sx={{
              width: { md: 40, lg: 60, xl: 70, xxl: 110, xxxl: 160 },
              height: { md: 40, lg: 60, xl: 70, xxl: 110, xxxl: 160 },
            }}
            aria-label='Avatar'
          >
            <CardMedia component='img' image={image} alt='Avatar' />
          </Avatar>
          <Box
            position='relative'
            sx={{
              marginLeft: { md: '8px', lg: '12px', xl: '18px', xxl: '22px', xxxl: '24px' },
            }}
          >
            <GenericHeading
              sx={{
                textTransform: 'uppercase',
                color: '#1C2842',
                fontSize: { md: '14px', lg: '16px', xl: '20px', xxl: '28px', xxxl: '40px' },
                lineHeight: { md: '18px', lg: '20px', xl: '22px', xxl: 'auto', xxxl: 'auto' },
                fontWeight: 600,
              }}
            >
              {name}
            </GenericHeading>
            <Stack
              direction='row'
              spacing={1}
              sx={{
                mt: { md: '7px', lg: '8x', xl: '10px', xxl: '12px', xxxl: '24px' },
              }}
            >
              {profileUrls.map(url => (
                <GenericLink
                  key={url}
                  href={url.replace(/\/$/, '')}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <img className={styles.sourceIcon} src={getSourceSrc(url)} alt='Profile' width={14} height={14} />
                </GenericLink>
              ))}
            </Stack>
            <Box className='details' sx={{ color: theme.palette.secondary.main }}>
              <EntityCardDetails svgIcon={markerIcon} value={location} />
              <EntityCardDetails
                svgIcon={cakeIcon}
                value={birthday ? moment(birthday).format('MMM. yyyy') : NOT_AVAILABLE}
              />
            </Box>
          </Box>
        </Stack>
      </CardContent>
    </GenericCard>
  )
}
export default EntityCard
