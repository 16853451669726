import React, { Component } from 'react'
import autobind from 'autobind-decorator'
import PropTypes from 'prop-types'
import cx from 'classnames'
import NoData from 'components/NoData'
import Input from 'components/Input'
import Icon from 'components/Icon'
import Income from 'components/Income'

import { SECTION_HOST } from 'containers/Rentals/specs'
import { camelCase } from 'lodash'
import BusinessSource from 'components/BusinessSource'
import styles from './RentalsListCell.scss'
import { extractRootDomainNoExt } from '../../../containers/NFT/specs'
import ReportedStatus from '../../ReportedStatus/ReportedStatus'

@autobind
class RentalsListCell extends Component {
  render() {
    const { cellName, item, onClick } = this.props

    return (
      <span
        className={cx(styles.cell, styles[cellName], {
          [styles.clickable]: !!onClick,
        })}
        onClick={onClick}
      >
        {this[cellName] ? this[cellName]() : item[cellName]}
        <Icon size='small' name='arrowUp' className={styles.expand} />
      </span>
    )
  }

  select() {
    const { isChecked, item, onSelect, children } = this.props

    return (
      <>
        {children}
        <Input type='checkbox' checked={isChecked} onChange={() => onSelect(item.id)} />
      </>
    )
  }

  hostName() {
    const { item } = this.props
    const { hostName = '' } = item

    return <span>{hostName}</span>
  }

  verifiedHostName() {
    const { item } = this.props
    const { verifiedHostName = '', isIdentityVerified = 0 } = item

    return (
      <div>
        {isIdentityVerified === 1 && <span>{verifiedHostName}</span>}
        {isIdentityVerified === 1 && (
          <span className={cx(styles.verified)}>
            <Icon name='verified' size='regular' />
          </span>
        )}
      </div>
    )
  }

  verifiedHostNameOther() {
    const { item } = this.props
    const { manualAdditions = {}, isIdentityVerified = 0 } = item

    return (
      <div>
        {isIdentityVerified === 1 && <span>{manualAdditions?.otherLanguageName}</span>}
        {manualAdditions?.otherLanguageName && (
          <span className={cx(styles.verified)}>
            <Icon name='verified' size='regular' />
          </span>
        )}
      </div>
    )
  }

  reportedStatus() {
    const { item } = this.props
    const { reportedStatus = {} } = item
    return <ReportedStatus reportedStatus={reportedStatus} id={item.id} />
  }

  birthday() {
    const { item } = this.props
    const { manualAdditions = {}, isIdentityVerified = 0 } = item

    return (
      <div>
        {isIdentityVerified === 1 && manualAdditions?.birthday && manualAdditions?.birthday.split('/').length === 3 && (
          <span>
            {new Date(manualAdditions?.birthday).toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </span>
        )}
        {isIdentityVerified === 1 && manualAdditions?.birthday && manualAdditions?.birthday.split('/').length === 2 && (
          <span>
            {new Date(manualAdditions?.birthday).toLocaleDateString('en-us', {
              month: 'long',
              day: 'numeric',
            })}
          </span>
        )}
      </div>
    )
  }

  hostType() {
    const { item } = this.props
    const { hostType = '' } = item

    return <span>{hostType}</span>
  }

  tin() {
    const { item } = this.props
    const { tin } = item

    return <span>{tin}</span>
  }

  numberOfListings() {
    const { item } = this.props
    const { numberOfListings = 0 } = item

    return <span>{numberOfListings}</span>
  }

  numberOfTotalListings() {
    const { item } = this.props
    const { numberOfTotalListings = 0 } = item

    return <span>{numberOfTotalListings}</span>
  }

  numberOfReviews() {
    const { item } = this.props
    const { numberOfReviews } = item

    if (typeof numberOfReviews !== 'number') return <NoData />

    return <span>{numberOfReviews}</span>
  }

  documentedRevenue() {
    const { item } = this.props
    const { totalRevenue = 0 } = item

    return <Income isFormatted={false} value={totalRevenue} />
  }

  reportedRevenue() {
    const { listType, item } = this.props
    const { reportedIncome = 0, reportedIncomeForHost = 0 } = item

    const value = listType === SECTION_HOST ? reportedIncomeForHost : reportedIncome

    if (!value || typeof value === 'string') return <NoData />
    return <Income isFormatted={false} value={value || 0} />
  }

  taxGap() {
    const { listType, item } = this.props
    const { taxGap = 0, taxGapForHost = 0 } = item

    const value = listType === SECTION_HOST ? taxGapForHost : taxGap

    if (!value || typeof value === 'string') return <NoData />
    return <Income isFormatted={false} value={value || 0} />
  }

  siteProfile() {
    const { item } = this.props
    const { hostVerificationPage, isIdentityVerified = 0 } = item

    const sites = typeof hostVerificationPage === 'string' ? [hostVerificationPage] : hostVerificationPage

    return (
      <div className={styles.webpage}>
        {isIdentityVerified === 1 &&
          sites
            .filter(url => url)
            .map(url => {
              const domain = extractRootDomainNoExt(url)
              return (
                <a key={url} href={url} target='_blank' rel='noreferrer'>
                  <BusinessSource source={camelCase(domain)} isClickable />
                </a>
              )
            })}
      </div>
    )
  }

  webpage() {
    const { item } = this.props
    const { airbnbHostProfile } = item

    return (
      <div className={styles.webpage}>
        <a href={airbnbHostProfile} target='_blank' rel='noreferrer'>
          <BusinessSource source='airbnb' isClickable />
        </a>
      </div>
    )
  }

  address() {
    const { item } = this.props
    const { hostStateLocator } = item

    if (!hostStateLocator || typeof hostStateLocator !== 'string') return <NoData />

    return <div className={styles.address}>{hostStateLocator}</div>
  }

  extendedAddress() {
    const { item } = this.props
    const { manualAdditions = {} } = item

    return <div className={styles.address}>{manualAdditions?.entityAddress}</div>
  }

  phoneNumber() {
    const { item } = this.props
    const { phoneNumber } = item

    return <span>{phoneNumber}</span>
  }

  email() {
    const { item } = this.props
    const { emailAddress } = item

    return <span>{emailAddress}</span>
  }

  listingNumber() {
    const { item } = this.props
    const { listingId } = item

    return <span>{listingId}</span>
  }

  listingSiteId() {
    const { children, item } = this.props
    const { listingId } = item

    return (
      <>
        {children}
        <span>{listingId}</span>
      </>
    )
  }

  listingAddress() {
    const { item } = this.props
    const { listingAddressEnriched } = item

    return <div className={styles.address}>{listingAddressEnriched}</div>
  }

  listingDescription() {
    const { item } = this.props
    const { listingDescription } = item

    return <div className={styles.description}>{listingDescription}</div>
  }

  listingSites() {
    const { item } = this.props
    const { listingId, deprecated } = item

    return (
      <div className={styles.webpage}>
        {!deprecated && (
          <a href={`https://www.airbnb.com/rooms/${listingId}`} target='_blank' rel='noreferrer'>
            <BusinessSource source='airbnb' isClickable />
          </a>
        )}
      </div>
    )
  }

  documentedIncome() {
    const { item } = this.props
    const { revenue = 0 } = item
    return <Income isFormatted={false} value={revenue} />
  }

  firstListedAt() {
    const { item } = this.props
    const { firstListedAt } = item

    return <span>{firstListedAt}</span>
  }

  numberOfReservations() {
    const { item } = this.props
    const { numberOfReservations = 14 } = item

    return (
      <span>
        <NoData />
      </span>
    )
  }

  daysRented() {
    const { item } = this.props
    const { daysRented } = item

    return <span>{daysRented}</span>
  }
}

RentalsListCell.propTypes = {
  cellName: PropTypes.string.isRequired,
  listType: PropTypes.string,
  item: PropTypes.object.isRequired,
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
}

RentalsListCell.defaultProps = {
  onSelect: () => {},
}

export default RentalsListCell
