import React, { useMemo } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import Income from 'components/Income'
import NoData from 'components/NoData/NoData'
import TableText from 'components/common/TableText'
import BusinessDataSources from 'components/BusinessDataSources/BusinessDataSources'
import { formattedDate } from 'utils/formatters'
import { extractRootDomainNoExt } from 'containers/NFT/specs'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { BaseEntityForTable, TABLE_TEXT_TYPES } from 'utils/types'
import { useBaseCellStyles } from 'components/verticals/VerticalTableBase/useBaseCellStyles'
import ReportedStatus from '../../ReportedStatus/ReportedStatus'
import { isNumber } from 'lodash'
import Tooltip from 'components/Tooltip'
import { NOT_AVAILABLE } from 'utils/constants'

export const useBaseColumns = <T extends BaseEntityForTable>({
  verticalTooltipValues,
}: {
  verticalTooltipValues?: Record<string, string>
}) => {
  const baseCellStyles = useBaseCellStyles()

  const revenueStyles = useMemo(
    () => ({
      muiTableHeadCellProps: { sx: { borderBottom: 'none' } },
      muiTableBodyCellProps: { sx: { ...baseCellStyles } },
      size: 150,
    }),
    [baseCellStyles],
  )

  const tooltipValues: Record<string, string> = useMemo(() => {
    const baseTooltipValues = {
      profileName: 'The profile name as appears in the web platform',
      verifiedName: 'The full name of the profile owner',
      tin: 'The official tax identification number of the identified entity',
      reportStatus:
        'The status of the tax reporting in each tax year after comparing the tax return reportings with the documented revenue',
      unreportedRevenue: 'Sum of the revenue that was not reported in the tax returns for all tax years',
      reported: 'Sum of the revenue that was reported in the tax returns for all tax years',
      firstActivity: 'The date of the first documented business activity as identified by IVIX',
      caseID: 'Unique IVIX id number for the case',
    }

    return verticalTooltipValues ? { ...baseTooltipValues, ...verticalTooltipValues } : baseTooltipValues
  }, [verticalTooltipValues])

  const baseColumns = useMemo<MRT_ColumnDef<T>[]>(() => {
    return [
      {
        header: 'Identification Details',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Identification details</TableText>,
        columns: [
          {
            id: 'profileName',
            header: 'Profile Names',
            enableSorting: false,
            Header: (
              <Tooltip title={tooltipValues.profileName}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Profile Names</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: { sx: { ...baseCellStyles } },
            size: 380,
            accessorFn: (item: BaseEntityForTable) => {
              const profileNames = item?.revenueSources?.reduce((currentNames, { profileName }) => {
                if (!currentNames.includes(profileName)) {
                  currentNames.push(profileName)
                }
                return currentNames
              }, [] as string[])
              return (
                <Box>
                  <Stack direction='row'>
                    {profileNames?.length === 0 && <></>}
                    {profileNames?.length === 1 && <TableText>{profileNames[0]}</TableText>}
                    {profileNames?.length > 1 && (
                      <Tooltip title={profileNames.join(', ')}>
                        <Stack direction='row' spacing={1}>
                          <TableText>{profileNames[0]}</TableText>
                          <TableText>{`+ ${profileNames.length - 1}`}</TableText>
                        </Stack>
                      </Tooltip>
                    )}
                  </Stack>
                </Box>
              )
            },
          },
          {
            id: 'entityDetails.name.keyword',
            header: 'Verified Name',
            Header: (
              <Tooltip title={tooltipValues.verifiedName}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Verified Name</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => (item?.entityDetails?.name ? <TableText>{item.entityDetails.name}</TableText> : <></>),
          },
          {
            id: 'entityDetails.tin',
            header: 'TIN',
            enableSorting: false,
            Header: (
              <Tooltip title={tooltipValues.tin}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>TIN</TableText>
              </Tooltip>
            ),
            muiTableHeadCellProps: {
              sx: { borderBottom: 'none' },
            },
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            size: 150,
            accessorFn: item =>
              item?.entityDetails?.tin ? <TableText>{item.entityDetails.tin}</TableText> : <NoData />,
          },
          {
            id: 'entityDetails.reportedStatus',
            header: 'Report Status',
            enableSorting: false,
            Header: (
              <Tooltip title={tooltipValues.reportStatus}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Report Status</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item =>
              item?.entityDetails?.reportedStatus ? (
                <ReportedStatus reportedStatus={item.entityDetails.reportedStatus} id={item.id} />
              ) : (
                <NoData />
              ),
          },
        ],
      },
      {
        header: 'Revenue',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Revenue</TableText>,
        columns: [
          {
            id: 'overviewDetails.taxGap',
            header: 'Unreported Revenue',
            Header: (
              <Tooltip title={tooltipValues.unreportedRevenue}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Unreported Revenue</TableText>
              </Tooltip>
            ),
            accessorFn: item => (
              <TableText>
                {item?.overviewDetails?.taxGap ? <Income value={item?.overviewDetails?.taxGap} /> : NOT_AVAILABLE}
              </TableText>
            ),
            muiTableHeadCellProps: {
              sx: { borderBottom: 'none' },
            },
            muiTableBodyCellProps: {
              sx: {
                ...baseCellStyles,
              },
            },
            size: 190,
          },
          {
            id: 'overviewDetails.reportedRevenue',
            header: 'Reported',
            Header: (
              <Tooltip title={tooltipValues.reported}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Reported</TableText>
              </Tooltip>
            ),
            accessorFn: item => (
              <TableText>
                {item?.overviewDetails?.reportedRevenue ? (
                  <Income value={item?.overviewDetails?.reportedRevenue} />
                ) : (
                  NOT_AVAILABLE
                )}
              </TableText>
            ),
            ...revenueStyles,
          },
          {
            id: 'totals.totalRevenue',
            header: 'Total Revenue',
            Header: (
              <Tooltip title={tooltipValues.totalRevenue || ''}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Revenue</TableText>
              </Tooltip>
            ),
            accessorFn: item => (
              <TableText>
                {item?.totals?.totalRevenue ? <Income value={item?.totals?.totalRevenue} /> : NOT_AVAILABLE}
              </TableText>
            ),
            muiTableHeadCellProps: {
              sx: { borderBottom: 'none' },
            },
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            size: 170,
          },
        ],
      },
      {
        header: 'Activity Details',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Activity Details</TableText>,
        columns: [
          {
            id: 'data sources',
            header: 'Data Sources',
            enableSorting: false,
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Data Sources</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            size: 300,
            accessorFn: (item: BaseEntityForTable) => {
              const { contactInfo, entityDetails, revenueSources } = item
              if (contactInfo && entityDetails && revenueSources) {
                const platforms: string[] = []
                revenueSources.forEach(({ platform }) => {
                  if (!platforms.includes(platform?.toLowerCase())) {
                    platforms.push(platform?.toLowerCase())
                  }
                })
                const dataSources = Object.keys(contactInfo.weblinks)
                dataSources?.forEach((source: string) => {
                  if (!platforms.includes(source?.toLowerCase())) {
                    platforms.push(source?.toLowerCase())
                  }
                })
                entityDetails.profileUrls?.forEach((source: string) => {
                  const platform = extractRootDomainNoExt(source)
                  if (!platforms.includes(platform?.toLowerCase())) {
                    platforms.push(platform?.toLowerCase())
                  }
                })

                return <BusinessDataSources dataSources={platforms?.sort() || []} />
              }

              return NOT_AVAILABLE
            },
          },
          {
            id: 'overviewDetails.activityType',
            header: 'Activity Type',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Activity Type</TableText>,
            enableSorting: false,
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item =>
              item?.overviewDetails?.activityType ? <TableText>{item?.overviewDetails.activityType}</TableText> : <></>,
          },
          {
            id: 'totals.firstActivity',
            header: 'First Activity',
            Header: (
              <Tooltip title={tooltipValues.firstActivity}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>First Activity</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => (
              <TableText>
                {isNumber(item?.totals?.firstActivity) || /^\d{4}$/.test(item?.totals?.firstActivity)
                  ? item?.totals?.firstActivity
                  : formattedDate(item?.totals?.firstActivity, 'MMM. YYYY')}
              </TableText>
            ),
          },
          {
            id: 'caseNumber',
            header: 'Case ID',
            Header: (
              <Tooltip title={tooltipValues.caseID}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Case ID</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => (item?.caseId ? <TableText>{item?.caseId}</TableText> : <div></div>),
          },
        ],
      },
    ]
  }, [])

  return baseColumns
}
