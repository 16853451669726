import { defineMessages } from 'react-intl'

const scope = 'app.containers.Navigation'

export default defineMessages({
  preview: {
    id: `${scope}.preview`,
    defaultMessage: 'Preview',
  },
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: 'Dashboard',
  },
  businesses: {
    id: `${scope}.businesses`,
    defaultMessage: 'Businesses',
  },
  rentals: {
    id: `${scope}.rentals`,
    defaultMessage: 'Short Term Rentals',
  },
  crypto: {
    id: `${scope}.crypto`,
    defaultMessage: 'Crypto',
  },
  influencers: {
    id: `${scope}.influencers`,
    defaultMessage: 'Influencers',
  },
  freelancers: {
    id: `${scope}.freelancers`,
    defaultMessage: 'Freelancers',
  },
  ecommerce: {
    id: `${scope}.ecommerce`,
    defaultMessage: 'Ecommerce',
  },
  cdtfa: {
    id: `${scope}.cdtfa`,
    defaultMessage: 'CDTFA',
  },
  businessRequests: {
    id: `${scope}.businessRequests`,
    defaultMessage: 'Business Requests',
  },
  management: {
    id: `${scope}.management`,
    defaultMessage: 'Management',
  },
  host: {
    id: `${scope}.host`,
    defaultMessage: 'Host',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  singleBusiness: {
    id: `${scope}.singleBusiness`,
    defaultMessage: 'Single Business',
  },
  offshore: {
    id: `${scope}.offshore`,
    defaultMessage: 'Offshore',
  },
})
