import React, { FC, useContext, useMemo } from 'react'
import GenericPieChart from 'components/common/GenericPieChart'
import ChartLegend from 'components/common/ChartLegend'
import GenericHeading from 'components/common/GenericHeading'
import GenericBarsChart from 'components/common/GenericBarsChart'
import VerticalOverview from 'containers/VerticalPage/common/VerticalOverview/VerticalOverview'
import ChartWrapper from 'containers/VerticalPage/common/VerticalOverview/ChartWrapper'
import FinancialOverview from 'containers/VerticalPage/common/VerticalOverview/FinancialOverview'
import { VerticalContext } from 'store/verticals/Context'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import {
  VerticalBarChartLoader,
  VerticalPieChartLoader,
  VerticalTabsHeadingLoader,
} from 'containers/VerticalPage/common/Loaders'
import DatePicker from 'components/common/DatePicker'
import { getLabelsForFinancialOverview, getVerticalRevenueByTotalFromEntity } from './utils'
import { getDefaultDateValues } from 'containers/VerticalPage/common/utils'
import { usePeriodSetter, useChartDataFetching } from 'containers/VerticalPage/common/hooks'
import { getTotalRevenueFromEntity, getVerticalRevenueBySourcesFromEntity } from '../../nft/Overview/utils'
import moment from 'moment'
import { NOT_AVAILABLE } from 'utils/constants'

const calendarIconUrl = require('components/common/images/calendar.svg')
const periodPopperPlacement = 'bottom-end'

interface OverviewProps {
  showGraph?: boolean
}

const periodFormat = 'MMM. yyyy'
const labelsForFinancialOverview = getLabelsForFinancialOverview()

const Overview: FC<OverviewProps> = ({ showGraph }) => {
  const { verticalEntity } = useContext(VerticalContext)

  const defaultDate = {
    start: moment('2010/01/01').format('YYYY/MM/DD'),
    end: moment().format('YYYY/MM/DD'),
  }
  const { period: piesPeriod, handlePeriodChange: handlePiesPeriodChange } = usePeriodSetter(getDefaultDateValues())
  const { period: barPeriod, handlePeriodChange: handleBarsPeriodChange } = usePeriodSetter(defaultDate)

  const totalGraphData = useMemo(
    () => getVerticalRevenueByTotalFromEntity(verticalEntity, piesPeriod),
    [verticalEntity, piesPeriod],
  )

  const totalRevenueGraphData = useMemo(
    () => getTotalRevenueFromEntity(verticalEntity, barPeriod),
    [verticalEntity, barPeriod],
  )

  const sourceGraphData = useMemo(
    () => getVerticalRevenueBySourcesFromEntity(verticalEntity, piesPeriod),
    [verticalEntity, piesPeriod],
  )

  const totalRevenueDataForFinancialOverview = useMemo(() => {
    const noDataAsNA = true
    return getTotalRevenueFromEntity(verticalEntity, barPeriod, noDataAsNA)
  }, [verticalEntity, barPeriod])

  const { isLoadingPies, isLoadingBar } = useChartDataFetching()

  const theme = useTheme()

  return (
    <VerticalOverview>
      <ChartWrapper>
        <Stack
          sx={{
            mb: { md: '6px', lg: '8px', xl: '12px', xxl: '18px', xxxl: '28px' },
            width: '100%',
          }}
        >
          {isLoadingPies ? (
            <VerticalTabsHeadingLoader />
          ) : (
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <GenericHeading
                sx={{
                  fontWeight: '600',
                  fontSize: { md: '14px', lg: '15px', xl: '18px', xxl: '28px', xxxl: '36px' },
                  color: '#1C2842',
                }}
              >
                REVENUE SOURCE: ALL
              </GenericHeading>
              <DatePicker
                periodStart={piesPeriod.start}
                periodEnd={piesPeriod.end}
                handlePeriodChange={handlePiesPeriodChange}
                format={periodFormat}
                popperPlacement={periodPopperPlacement}
                isCharts
                iconUrl={calendarIconUrl}
              />
            </Stack>
          )}
        </Stack>
        {isLoadingPies ? (
          <VerticalPieChartLoader />
        ) : (
          <Stack
            direction='row'
            width='100%'
            justifyContent='space-around'
            alignItems='center'
            sx={{
              flex: 1,
            }}
          >
            <Stack flexDirection={showGraph ? 'column' : 'row-reverse'} alignItems='center'>
              {sourceGraphData ? (
                <Stack
                  alignItems='center'
                  sx={{
                    width: { md: '75px', lg: '125px', lgPlus: '165px', xl: '165px', xxl: '250px', xxxl: '350px' },
                    ml: showGraph ? {} : { md: '8px', lg: '16px', xl: '24px', xxl: '34px', xxxl: '40px' },
                  }}
                >
                  <GenericHeading
                    textAlign='center'
                    sx={{
                      fontSize: { md: '13px', lg: '14px', xl: '16px', xxl: '26px', xxxl: '38px' },
                      color: '#103072',
                      fontWeight: 600,
                    }}
                  >
                    Sources
                  </GenericHeading>
                  <GenericPieChart chartData={sourceGraphData} />
                </Stack>
              ) : (
                NOT_AVAILABLE
              )}
              {sourceGraphData ? <ChartLegend legendNames={sourceGraphData.map(datum => datum.name)} /> : NOT_AVAILABLE}
            </Stack>
            <Stack flexDirection={showGraph ? 'column' : 'row-reverse'} alignItems='center'>
              {totalGraphData ? (
                <Stack
                  alignItems='center'
                  sx={{
                    width: { md: '75px', lg: '125px', lgPlus: '165px', xl: '165px', xxl: '250px', xxxl: '350px' },
                    ml: showGraph ? {} : { md: '8px', lg: '16px', xl: '24px', xxl: '34px', xxxl: '40px' },
                  }}
                >
                  <GenericHeading
                    textAlign='center'
                    sx={{
                      fontSize: { md: '13px', lg: '14px', xl: '16px', xxl: '26px', xxxl: '38px' },
                      color: '#103072',
                      fontWeight: 600,
                    }}
                  >
                    Total
                  </GenericHeading>
                  <GenericPieChart chartData={totalGraphData} />
                </Stack>
              ) : (
                NOT_AVAILABLE
              )}
              {totalGraphData ? <ChartLegend legendNames={totalGraphData.map(datum => datum.name)} /> : NOT_AVAILABLE}
            </Stack>
          </Stack>
        )}
      </ChartWrapper>

      <ChartWrapper>
        {isLoadingBar ? (
          <VerticalBarChartLoader />
        ) : (
          <Stack width='100%' height='100%'>
            {isLoadingBar ? (
              <VerticalTabsHeadingLoader />
            ) : (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'
                sx={{ pb: { xxl: '22px' } }}
              >
                <GenericHeading
                  sx={{
                    fontSize: { md: '14px', lg: '15px', xl: '18px', xxl: '28px', xxxl: '36px' },
                    color: '#1C2842',
                    fontWeight: 600,
                  }}
                >
                  TOTAL REVENUE
                </GenericHeading>
                <DatePicker
                  popperPlacement={periodPopperPlacement}
                  periodStart={barPeriod.start}
                  periodEnd={barPeriod.end}
                  handlePeriodChange={handleBarsPeriodChange}
                  format={periodFormat}
                  isCharts
                  iconUrl={calendarIconUrl}
                />
              </Stack>
            )}
            <Stack sx={{ flexGrow: 1 }}>
              <Stack
                direction='row'
                width='100%'
                alignItems='center'
                gap='4px'
                sx={{
                  borderBlockEnd: '1px solid #DFDFDF',
                }}
              >
                <Stack width='25%'>
                  <ChartLegend legendNames={['Reported', 'Documented', 'Exact']} />
                </Stack>
                <Stack width='75%' height={{ md: 130, xl: 180, xxl: 250, xxxl: 600 }}>
                  {totalRevenueGraphData && (
                    <GenericBarsChart
                      chartData={totalRevenueGraphData}
                      xAxisDataKey='date'
                      barDataKey1='reported'
                      stackedBarDataKey1='documented'
                      barDataKey2={'exact'}
                      labels={{ exact: 'Exact' }}
                    />
                  )}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  overflowX: 'auto',
                  maxWidth: '100%',
                  width: '100%',
                  marginTop: 'auto',
                  ...theme.mixins.customScrollBar(),
                }}
              >
                {totalRevenueDataForFinancialOverview && (
                  <FinancialOverview
                    financialData={totalRevenueDataForFinancialOverview}
                    labels={labelsForFinancialOverview}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </ChartWrapper>
    </VerticalOverview>
  )
}

export default Overview
