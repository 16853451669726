import React, { FC, useMemo } from 'react'

import { EcommerceEntityForTable } from 'containers/EcommerceTablePage/types'
import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'
import TableText from 'components/common/TableText'
import Income from 'components/Income'

import { formatNumber } from 'utils/formatters'

import { MRT_ColumnDef } from 'material-react-table'
import { TABLE_TEXT_TYPES } from 'utils/types'
import { useBaseCellStyles } from 'components/verticals/VerticalTableBase/useBaseCellStyles'
import { NOT_AVAILABLE } from 'utils/constants'

const EcommerceTable: FC<VerticalTableCMPBaseProps<EcommerceEntityForTable>> = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
  sorting,
  setSorting,
}) => {
  const baseCellStyles = useBaseCellStyles()
  const revenueStyles = {
    muiTableHeadCellProps: {
      sx: { borderBottom: 'none' },
    },
    muiTableBodyCellProps: {
      sx: { ...baseCellStyles },
    },
    size: 150,
  }
  const columns = useMemo<MRT_ColumnDef<EcommerceEntityForTable>[]>(
    () => [
      {
        header: 'Revenue',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Revenue</TableText>,
        columns: [
          {
            id: 'totals.documentedRevenue',
            header: 'Documented Revenue',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Documented Revenue</TableText>,
            accessorFn: item => (
              <TableText>
                {item?.totals?.documentedRevenue ? <Income value={item.totals.documentedRevenue} /> : NOT_AVAILABLE}
              </TableText>
            ),
            ...revenueStyles,
            size: 200,
          },
          {
            id: 'totals.estimatedRevenue',
            header: 'Estimated Revenue',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Estimated Revenue</TableText>,
            accessorFn: item => (
              <TableText>
                {item?.totals?.estimatedRevenue ? <Income value={item?.totals?.estimatedRevenue} /> : NOT_AVAILABLE}
              </TableText>
            ),
            ...revenueStyles,
            size: 180,
          },
        ],
      },
      {
        header: 'Activity Details',
        columns: [
          {
            id: 'totals.totalItemsSold',
            header: 'Total Sold Items',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Sold Items</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            accessorFn: item => (
              <TableText>
                {formatNumber(item?.totals.totalItemsSold, null, {
                  style: 'decimal',
                })}
              </TableText>
            ),
          },
          {
            id: 'totals.totalReviews',
            header: 'Total Reviews',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Reviews</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            accessorFn: item => (
              <TableText>
                {formatNumber(item?.totals.totalReviews, null, {
                  style: 'decimal',
                })}
              </TableText>
            ),
          },
        ],
      },
    ],
    [],
  )

  return (
    <VerticalTableBase<EcommerceEntityForTable>
      columns={columns}
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
      sorting={sorting}
      setSorting={setSorting}
    />
  )
}

export default EcommerceTable
