import React, { useCallback, useEffect, useMemo, useRef, UIEvent } from 'react'
import { MRT_ColumnDef, MRT_Row, MRT_RowVirtualizer, MRT_SortingState } from 'material-react-table'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { BaseEntityForTable } from 'utils/types'
import { TABLE_BOTTOM, selectCheckboxIconsConfig } from './config'
import { useTableStyles } from './useTableStyles'
import { useLocation } from 'react-router-dom'
import Icon from 'components/Icon'
import styles from './styles.scss'

const sortIcon = require('./icons/sort.svg')

interface BaseTableOptions<T extends BaseEntityForTable> {
  columns: MRT_ColumnDef<T>[]
  items: T[] | null
  isFetching: boolean
  totalFetched: number
  totalDBRowCount: number
  sorting: MRT_SortingState
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<T, unknown>>
}

export const useBaseTableOptions = <T extends BaseEntityForTable>({
  columns,
  items,
  isFetching,
  totalFetched,
  totalDBRowCount,
  sorting,
  setSorting,
  fetchNextPage,
}: BaseTableOptions<T>) => {
  const { pathname } = useLocation<{ pathname: string }>()

  const tableContainerRef = useRef<HTMLDivElement>(null)

  // Recommanded when we'll have more than 100 rows
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null)

  const tableStyles = useTableStyles()

  const onRowClick = (rowData: MRT_Row<T>) =>
    window.open(`${window.location.origin}/vertical${pathname}/${rowData?.original?.id}`, '_blank')

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        const didUserReachBottom = scrollHeight - scrollTop - clientHeight < TABLE_BOTTOM
        const areThereMoreRowsToFetch = totalFetched < totalDBRowCount
        if (didUserReachBottom && !isFetching && areThereMoreRowsToFetch) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  )

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  const handleScroll = (event: UIEvent<HTMLDivElement>) => fetchMoreOnBottomReached(event.target as HTMLDivElement)

  // const renderDetailPanel = ({ row }: { row: MRT_Row<T> }) => {
  //   const subColumns = useMemo<MRT_ColumnDef<any>[]>(
  //     () => [
  //       {
  //         accessorKey: 'id',
  //         header: 'ID',
  //         size: 50,
  //       },
  //       {
  //         accessorKey: 'name',
  //         header: 'Full Name',
  //       },
  //       {
  //         accessorKey: 'age',
  //         header: 'Age',
  //       },
  //     ],
  //     [],
  //   )

  //   const subData = [
  //     { id: 1, name: 'John Doe', age: 25 },
  //     { id: 2, name: 'Jane Smith', age: 30 },
  //   ]

  // const subTable = useMaterialReactTable({
  //   columns: subColumns,
  //   data: subData,
  //   enableRowSelection: false,
  //   enableColumnOrdering: false,
  //   enableGlobalFilter: false,
  //   enableColumnActions: false,
  //   enableColumnFilters: false,
  //   enablePagination: false,
  //   enableSorting: true,
  //   manualSorting: true,
  //   enableBottomToolbar: false,
  //   enableTopToolbar: false,
  // })

  //   return <MaterialReactTable table={subTable} />
  // }

  const tableOptions = useMemo(() => {
    return {
      columns,
      data: items || [],
      enableStickyHeader: true,
      enableRowSelection: true,
      enableColumnOrdering: true,
      enableGlobalFilter: false,
      enableTopToolbar: false,
      enablePagination: false,
      enableSorting: true,
      manualSorting: true,
      enableBottomToolbar: false,
      enableColumnActions: false,
      rowVirtualizerInstanceRef: rowVirtualizerInstanceRef,
      rowVirtualizerOptions: { overscan: 4 },
      // renderDetailPanel: renderDetailPanel, // temp removal
      muiTableContainerProps: {
        // Get access to the table container element
        ref: tableContainerRef,
        onScroll: handleScroll,
        sx: tableStyles.muiTableContainerProps,
      },
      muiTableBodyRowProps: ({ row }: { row: MRT_Row<T> }) =>
        pathname !== '/offshore'
          ? {
              onClick: () => onRowClick(row),
              sx: tableStyles.muiTableBodyRowProps,
            }
          : {
              sx: { backgroundColor: '#f4f4f6' },
            },
      muiSelectAllCheckboxProps: () => ({
        ...selectCheckboxIconsConfig,
        indeterminateIcon: <Icon path='assets' name='deselect' size='small' />,
      }),
      muiSelectCheckboxProps: () => selectCheckboxIconsConfig,
      muiTablePaperProps: {
        sx: tableStyles.muiTablePaperProps,
      },
      muiTableHeadCellProps: {
        sx: tableStyles.muiTableHeadCellProps,
      },
      muiTableBodyCellProps: {
        sx: tableStyles.muiTableBodyCellProps,
      },
      icons: {
        ArrowDownwardIcon: (data: { className: string }) => {
          return <img className={`${styles.sortIcon} ${data.className}`} src={sortIcon} alt='sort' />
        },
        DragHandleIcon: () => <DragHandleIcon className={styles.dragIcon} />,
      },
      localization: { move: 'Drag Column' },
      state: { sorting },
      onSortingChange: setSorting,
      manualFiltering: true,
    }
  }, [columns, items, isFetching])

  return tableOptions
}
