import { useQuery } from '@tanstack/react-query'
import { fetchVerticalTableOverviewData } from 'services/verticalsTablesApi'
import { BaseTableOverview } from 'utils/types'

interface UseVerticalOverviewQueryProps {
  verticalIndices: string
  currentQueryKey: string
  searchValue?: string
}

export const useVerticalOverviewQuery = <T extends BaseTableOverview>({
  verticalIndices,
  currentQueryKey,
  searchValue,
}: UseVerticalOverviewQueryProps) =>
  useQuery<T | null>({
    queryKey: [currentQueryKey, verticalIndices, searchValue],
    queryFn: () => (verticalIndices ? fetchVerticalTableOverviewData(verticalIndices, searchValue) : null),
  })
