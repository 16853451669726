import React, { useContext, useMemo, useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CircleIcon from '@mui/icons-material/Circle'
import InfoIcon from '@mui/icons-material/Info'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  ReferenceLine,
  YAxis,
  LabelList,
  LabelProps,
} from 'recharts'
import { VerticalContext } from 'store/verticals/Context'
import { businessTypeUrls, businessTypeLabels, tableHeaders, getDividerValueByBarChartType } from './utils'
import { usePeriodSetter } from 'containers/VerticalPage/common/hooks'
import { getDefaultDateValues, getDateByYearsLater } from 'containers/VerticalPage/common/utils'
import {
  convertQuarterlyRevenueToYearly,
  barChartData,
  TaxData,
  findMinMaxValues,
  BarChartType,
  getTotalRevenueFromEntityQuarterly,
  barChartDataKeys,
  getPossibleDates,
} from './utils'
import { formatNumber } from 'utils/formatters'
import { getAbbreviatedNumber } from 'components/Income/Income'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import DatePicker from 'components/common/DatePicker'
import styles, { fontFamily } from './styles'
import { Link } from 'react-router-dom'
import { IDetailsSources } from 'store/verticals/types'
import TextTooltip from 'components/Tooltip'
import Select from 'components/Select/Select'
import { getIconByDiffValueNumber } from './graphDiffIcons'
import moment from 'moment'

const defaultDate = getDefaultDateValues()
const calendarIconUrl = require('../icons/calendar.svg')

const OverviewTab = () => {
  const { verticalEntity } = useContext(VerticalContext)
  const { period: barPeriod, handlePeriodChange: handleBarsPeriodChange } = usePeriodSetter(defaultDate)

  const [barChartType, setBarChartType] = useState<BarChartType>(BarChartType.ANNUALLY)
  const [selectedDifferenceFirst, setSelectedDifferenceFirst] = useState<string>(barChartDataKeys.totalSalesTaxesDue)
  const [selectedDifferenceSecond, setSelectedDifferenceSecond] = useState<string>(
    barChartDataKeys.totalTaxesPaidByIvix,
  )
  const [barPeriodWasMounted, setBarPeriodWasMounted] = useState<boolean>(false)

  const totalRevenueGraphData = useMemo(() => {
    const quarterlyData = getTotalRevenueFromEntityQuarterly(verticalEntity, barPeriod)

    return barChartType === BarChartType.QUARTERLY ? quarterlyData : convertQuarterlyRevenueToYearly(quarterlyData)
  }, [verticalEntity, barPeriod, barChartType])

  const rangeValues = useMemo(() => findMinMaxValues(totalRevenueGraphData), [totalRevenueGraphData])
  const rangeYears = useMemo(() => {
    const quarterlyData = getTotalRevenueFromEntityQuarterly(verticalEntity)
    const yearlyData = convertQuarterlyRevenueToYearly(quarterlyData)

    return yearlyData.map(value => Number(value.date))
  }, [verticalEntity])

  const possibleDates = useMemo(() => getPossibleDates(rangeYears, barChartType), [rangeYears, barChartType])

  const selectedDifferenceFirstData = useMemo(() => {
    const data = barChartData.find(el => el.key === selectedDifferenceFirst)
    return data
  }, [selectedDifferenceFirst])

  const selectedDifferenceSecondData = useMemo(() => {
    const data = barChartData.find(el => el.key === selectedDifferenceSecond)
    return data
  }, [selectedDifferenceSecond])

  useEffect(() => {
    if (!barPeriodWasMounted && possibleDates.end.max) {
      setBarPeriodWasMounted(true)

      handleBarsPeriodChange({
        startDate: getDateByYearsLater(-(getDividerValueByBarChartType(barChartType) - 1), `${possibleDates.end.max}`),
        endDate: moment(possibleDates.end.max).format('YYYY/MM/DD'),
      })
    }
  }, [possibleDates, barChartType])

  const handleBarTypeChange = (type: BarChartType) => {
    setBarChartType(type)
    handleBarsPeriodChange({
      startDate: getDateByYearsLater(-(getDividerValueByBarChartType(type) - 1), `${possibleDates.end.max}`),
      endDate: moment(possibleDates.end.max).format('YYYY/MM/DD'),
    })
  }

  return (
    <Stack>
      <Stack direction='row' alignItems='center'>
        <Typography sx={styles.Title}>Account Sales Tax</Typography>
        {/* We will need it for next the version, please not remove ! */}
        {/* <Stack direction='row' alignItems='center' gap={1.5}>
          <Typography sx={styles.SelectorsTitle}>Difference Value Between</Typography>
          <Select
            list={barChartData
              .filter(item => ![selectedDifferenceFirst, selectedDifferenceSecond].includes(item.key))
              .map(item => ({ id: item.key, ...item }))}
            selected={selectedDifferenceFirst}
            onChange={type => setSelectedDifferenceFirst(type as string)}
          >
            {selectedDifferenceFirstData?.color && (
              <CircleIcon
                htmlColor={selectedDifferenceFirstData?.color}
                sx={{ width: '10px', height: '10px', mr: 1.5 }}
              />
            )}
            {selectedDifferenceFirstData?.label || 'Select'}
          </Select>
          <Typography sx={styles.SelectorsTitle}>and</Typography>
          <Select
            list={barChartData
              .filter(item => ![selectedDifferenceFirst, selectedDifferenceSecond].includes(item.key))
              .map(item => ({ id: item.key, ...item }))}
            selected={selectedDifferenceSecond}
            onChange={type => setSelectedDifferenceSecond(type as string)}
          >
            {selectedDifferenceSecondData?.color && (
              <CircleIcon
                htmlColor={selectedDifferenceSecondData?.color}
                sx={{ width: '10px', height: '10px', mr: 1.5 }}
              />
            )}
            {selectedDifferenceSecondData?.label || 'Select'}
          </Select>
        </Stack> */}
        <Stack sx={{ ml: 2 }}>
          <TextTooltip
            position='bottom'
            html={
              <Typography sx={styles.TooltipText}>
                Annually view presents maximum 10 years, Quarterly view presents maximum 12 quarters
              </Typography>
            }
            theme='light'
          >
            <InfoIcon htmlColor='rgba(126, 139, 166, 1)' sx={{ width: '20px', height: '20px', ml: 1 }} />
          </TextTooltip>
        </Stack>
        <ButtonGroup variant='contained' sx={styles.ButtonGroup}>
          <Button
            sx={{ ...styles.Button, ...(BarChartType.ANNUALLY === barChartType ? styles.ActiveButton : {}) }}
            onClick={() => handleBarTypeChange(BarChartType.ANNUALLY)}
          >
            Annually
          </Button>
          <Button
            sx={{ ...styles.Button, ...(BarChartType.QUARTERLY === barChartType ? styles.ActiveButton : {}) }}
            onClick={() => handleBarTypeChange(BarChartType.QUARTERLY)}
          >
            Quarterly
          </Button>
        </ButtonGroup>
        <Stack sx={styles.DatePickerContainer}>
          {rangeYears.length > 0 && (
            <DatePicker
              periodStart={barPeriod.start}
              periodEnd={barPeriod.end}
              handlePeriodChange={newDatePeriod => {
                const yearsRange = getDividerValueByBarChartType(barChartType) - 1
                if (newDatePeriod.fieldChanged === 'startDate') {
                  handleBarsPeriodChange({
                    startDate: newDatePeriod.startDate,
                    endDate: getDateByYearsLater(yearsRange, newDatePeriod.startDate),
                  })
                }
                if (newDatePeriod.fieldChanged === 'endDate') {
                  handleBarsPeriodChange({
                    startDate: getDateByYearsLater(-yearsRange, newDatePeriod.endDate),
                    endDate: newDatePeriod.endDate,
                  })
                }
              }}
              format='yyyy'
              isCharts
              iconUrl={calendarIconUrl}
              showYearPicker
              iconClassName='calendarIcon'
              minMaxValues={possibleDates}
            />
          )}
        </Stack>
      </Stack>
      <Stack width='100%' height='240px' mt={2}>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart data={totalRevenueGraphData} barGap={10} barCategoryGap={0}>
            <CartesianGrid
              verticalCoordinatesGenerator={data => {
                const margin = 60
                const step = (data?.offset?.width || 0) / data?.xAxis?.domain?.length
                const coordinates = data?.xAxis?.domain?.map((e: string, index: number) => index * step + step + margin)
                return coordinates.slice(0, coordinates.length - 1)
              }}
              stroke='#DADBDF'
              strokeWidth={0.5}
            />
            <XAxis
              dataKey='date'
              tickLine={false}
              fontFamily={fontFamily}
              fontSize='16px'
              axisLine={{ stroke: 'transparent' }}
              dy={5}
              stroke='#1C2842'
            />
            <YAxis
              dataKey='totalSalesTaxesDue'
              fontFamily={fontFamily}
              fontSize='16px'
              axisLine={{ stroke: 'transparent' }}
              tickFormatter={num => formatNumber(num)}
              dx={-10}
              stroke='#1C2842'
              domain={[rangeValues.min - Math.abs(rangeValues.min) * 0.4, rangeValues.max + rangeValues.max * 0.4]}
            />
            <ReferenceLine y={0} stroke='#b0b3b6' strokeWidth={2} />
            {[selectedDifferenceFirst, selectedDifferenceSecond]
              .map(key => barChartData.find(el => el.key === key))
              .filter(el => Boolean(el))
              .map((chartData, index) => (
                <Bar
                  isAnimationActive={false}
                  key={chartData!.key}
                  dataKey={chartData!.key}
                  fill={chartData!.color}
                  radius={[6, 6, 0, 0]}
                  barSize={20}
                  fontFamily={fontFamily}
                  name={chartData!.label}
                >
                  {index === 1 && (
                    <LabelList
                      content={(props: Omit<React.SVGProps<SVGTextElement>, 'viewBox'> & LabelProps) => {
                        // @ts-ignore: Unreachable code error
                        const { x, width, index } = props

                        const yPosition = 30
                        if (typeof index !== 'number') return <g></g>

                        const value = totalRevenueGraphData[index]

                        const diffValueNumber = value[selectedDifferenceSecond] - value[selectedDifferenceFirst]
                        const resultValue = getAbbreviatedNumber(Math.abs(diffValueNumber))
                        const finalStringValue =
                          resultValue.finalNumber !== 0 ? `$${resultValue.finalNumber}${resultValue.unit}` : 'No Diff.'

                        const offsetX = (finalStringValue.length * 8.4) / 2
                        const pathTransform = `translate(${Number(x) - offsetX - 15}, ${Number(yPosition) - 7})`
                        const noDiffPathTransform = `translate(${Number(x) - offsetX - 10}, ${Number(yPosition) - 11})`

                        const icon = getIconByDiffValueNumber(diffValueNumber, pathTransform, noDiffPathTransform)

                        return (
                          <g>
                            {icon}
                            <text
                              x={Number(x) + Number(width) / 2}
                              y={yPosition}
                              fill='#1C2842'
                              textAnchor='middle'
                              dominantBaseline='middle'
                              fontFamily={fontFamily}
                              fontSize={16}
                            >
                              {finalStringValue}
                            </text>
                          </g>
                        )
                      }}
                    />
                  )}
                  <LabelList
                    position='top'
                    valueAccessor={({ payload }: { payload: TaxData }) => {
                      return formatNumber(payload[chartData!.key])
                    }}
                    fontFamily={fontFamily}
                    fontSize={14}
                    fontWeight={400}
                    style={{ fill: '#1C2842' }}
                    dx={(() => {
                      if (index === 0) return -10
                      if (index === 1) return 10
                      return 0
                    })()}
                  />
                </Bar>
              ))}
          </BarChart>
        </ResponsiveContainer>
      </Stack>
      <Stack flexDirection='row' mt={1}>
        {[selectedDifferenceFirst, selectedDifferenceSecond]
          .map(key => barChartData.find(el => el.key === key))
          .filter(el => Boolean(el))
          .map(data => (
            <Stack key={data!.key} flexDirection='row' alignItems='center' mr={4}>
              <CircleIcon htmlColor={data!.color} sx={{ width: '10px', height: '10px', marginRight: '6px' }} />
              <Typography color='#1C2842' fontSize={{ md: 15, lg: 15, xl: 16 }} fontFamily='Source Sans Pro'>
                {data!.label}
              </Typography>
            </Stack>
          ))}
      </Stack>
      <Divider sx={{ bgcolor: '#e0e0e0', mt: { md: 2, lg: 2, xl: 3 }, mb: { md: 2, lg: 2, xl: 3 } }} />
      <Typography sx={styles.Title}>Site Web Links</Typography>
      <Stack direction='row'>
        <TableContainer sx={styles.TableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map(head => (
                  <TableCell key={head}>{head}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {verticalEntity?.detailsSources?.map((row: IDetailsSources, index) => {
                const totalReviews = row?.overview?.totalReviews
                  ? getAbbreviatedNumber(row?.overview?.totalReviews)
                  : null

                return (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      {businessTypeUrls[row.platform] && (
                        <Box
                          component='img'
                          src={businessTypeUrls[row.platform]}
                          width={20}
                          height={20}
                          mr={1}
                          alt={row.platform}
                        />
                      )}
                      <Link to={{ pathname: row.url }} target='_blank'>
                        {businessTypeLabels[row.platform] || 'N/A'}
                      </Link>
                    </TableCell>
                    <TableCell>{row.name || 'N/A'}</TableCell>
                    <TableCell>{row.overview?.businessType || 'N/A'}</TableCell>
                    <TableCell>{row.overview?.businessCategories?.join(', ') || 'N/A'}</TableCell>
                    <TableCell className='long-width'>{row?.overview?.attributes?.join(', ') || 'N/A'}</TableCell>
                    <TableCell>
                      {row.overview?.priceRange
                        ? Array.from(Array(row.overview?.priceRange).keys())
                            ?.map(() => '$')
                            ?.join('')
                        : 'N/A'}
                    </TableCell>
                    <TableCell>{totalReviews ? `${totalReviews?.finalNumber}${totalReviews?.unit}` : 'N/A'}</TableCell>
                    <TableCell>{row?.overview?.firstActivity || 'N/A'}</TableCell>
                    <TableCell>{row?.overview?.lastActivity || 'N/A'}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  )
}

export default OverviewTab
