import bcrypt from 'bcryptjs'
import autobind from 'autobind-decorator'
import axios from 'services/axios'
import localStorageService from 'services/storage'
import filtersService from 'services/filters'
import history from 'utils/history'
import { logout } from 'store/global'
import { store } from '../app'

export const batchSize = process.env.BATCH_SIZE || 100

@autobind
class ApiService {
  async handleApiError(error = {}, urlMessage) {
    const { response = {} } = error
    const { data = {}, status } = response
    const { message } = data
    const isLoginService = process.env.SERVICE === 'login' && process.env.LOCAL_LOGIN === 'false'
    const isLocalLogin = String(process.env.LOCAL_LOGIN).toLowerCase() === 'true'

    // If we get a 401 response, that means the authentication cookie has expired, and the user is no longer logged in
    if (status === 401) {
      if (isLoginService) return
      localStorageService.clearAllFilters()
      await store.dispatch(logout())
      if (isLocalLogin) {
        history.push(`/login?${urlMessage || 'expired=true'}`)
      }
      // else if (isMainService) {
      // window.location.href = process.env.LOGIN_URL;
      // }
      // } else {
      // console.error('IS THIS MODE POSSIBLE?', process.env.LOCAL_LOGIN);
      /// / localStorageService.clearAllFilters();
      /// / history.push(`/login?${urlMessage || 'expired=true'}`);
      // }
    }

    if (status === 403) {
      history.push('/')
      return
    }

    if (message.includes('500')) {
      history.push('/error/500')
      return
    }

    console.error('API Error: ', error)
  }

  async currentUser() {
    const endpoint = 'auth/currentUser'

    try {
      const { data } = await axios.get(endpoint)
      const { currentUser, availableZones } = data

      return { currentUser, availableZones }
    } catch (err) {
      this.handleApiError(err, 'invalidUserResponse=true')
    }
  }

  async login(email, password) {
    const endpoint = 'auth/login'

    const encodedPassword = btoa(password)

    const postData = {
      email,
      password: encodedPassword,
    }

    try {
      const { data } = await axios.post(endpoint, postData)
      const { currentUser, availableZones, platforms, isProvisional } = data
      if (isProvisional) return { isProvisional }
      return {
        currentUser,
        availableZones,
        platforms,
      }
    } catch (error) {
      console.error(error)
      throw new Error(error.response.data?.message)
    }
  }

  async logout() {
    const endpoint = 'auth/logout'

    try {
      await axios.post(endpoint)
      return true
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async dashboardTransmissions() {
    const endpoint = 'dashboard/transmissions'

    try {
      const { data } = await axios.get(endpoint)
      const { events } = data

      return { transmissions: events }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async userRoles() {
    const endpoint = 'user-roles'

    try {
      const { data } = await axios.get(endpoint)
      const { roles } = data

      return { roles }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async zones() {
    const endpoint = 'zones'

    try {
      const { data } = await axios.get(endpoint)
      const { zones } = data

      return { zones }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async submitZone(zone) {
    const endpoint = 'zones'

    const isCreate = !zone.id
    const method = isCreate ? 'post' : 'put'

    const { id, slug, name } = zone

    const body = {
      id,
      slug,
      name,
    }

    try {
      const { data } = await axios[method](endpoint, body)

      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async deleteZone(id) {
    const endpoint = 'zones'

    const config = {
      params: {
        id,
      },
    }

    try {
      const { data } = await axios.delete(endpoint, config)

      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async organizations() {
    const endpoint = 'organizations'

    try {
      const { data } = await axios.get(endpoint)
      const { organizations } = data

      return { organizations }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async submitOrganization(organization) {
    const endpoint = 'organizations'

    const isCreate = !organization.id
    const method = isCreate ? 'post' : 'put'

    const { id, slug, name, zoneIds } = organization

    const body = {
      id,
      slug,
      name,
      zoneIds,
    }

    try {
      const { data } = await axios[method](endpoint, body)

      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async deleteOrganization(organizationId) {
    const endpoint = 'organizations'

    const config = {
      params: {
        organizationId,
      },
    }

    try {
      const { data } = await axios.delete(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async users() {
    const endpoint = 'users'

    try {
      const { data } = await axios.get(endpoint)
      const { users } = data

      return { users }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async platforms() {
    const endpoint = 'platforms'

    try {
      const { data } = await axios.get(endpoint)
      const { platforms } = data

      return { platforms }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async submitUser(user) {
    const endpoint = 'users'

    const isCreate = !user.id
    const method = isCreate ? 'post' : 'put'

    const { id, email, password, fullName, organization, role, platforms } = user

    try {
      const salt = bcrypt.genSaltSync(10)
      const hashedPassword = bcrypt.hashSync(password, salt)

      const body = {
        id,
        email: email.toLowerCase(),
        password: hashedPassword,
        fullName,
        organizationId: parseInt(organization),
        roleId: parseInt(role),
        platforms,
      }

      const { data } = await axios[method](endpoint, body)

      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async createBulkUsers(users) {
    const endpoint = 'users/bulk'

    try {
      const salt = bcrypt.genSaltSync(10)

      const body = {
        users: users.map(user => {
          const hashedPassword = bcrypt.hashSync(user.password, salt)
          return { ...user, password: hashedPassword }
        }),
      }

      const { data } = await axios.post(endpoint, body)

      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async deleteUser(email) {
    const endpoint = 'users'

    const config = {
      params: {
        email,
      },
    }

    try {
      const { data } = await axios.delete(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async changePassword(currentUser, newPassword) {
    const endpoint = 'users/change-password'

    try {
      const salt = bcrypt.genSaltSync(10)
      const hashedPassowrd = bcrypt.hashSync(newPassword, salt)

      const body = {
        userId: currentUser.id,
        newPassword: hashedPassowrd,
      }

      const { data } = await axios.post(endpoint, body)
      return data
    } catch (err) {
      console.error(error)
      return false
    }
  }

  async updatePassword(currentUser, currentPassword, newPassword) {
    const endpoint = 'users/update-password'

    const body = {
      userId: currentUser.id,
      currentPassword,
      newPassword,
    }

    return await axios.post(endpoint, body)
  }

  async configureMap() {
    const endpoint = 'config/map'

    try {
      const { data } = await axios.get(endpoint)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async configureInitiallySelectedBusinesses() {
    const endpoint = 'config/selected'

    try {
      const { data } = await axios.get(endpoint)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async configurePlatform() {
    const endpoint = 'config/platform'

    try {
      const { data } = await axios.get(endpoint)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async configureStaticFiles() {
    const endpoint = 'config/staticFiles'

    try {
      const { data } = await axios.get(endpoint)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async dashboard(zoneEndpoint) {
    const endpoint = 'dashboard'

    const params = {
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async businessTypes(zoneEndpoint, filters) {
    const endpoint = 'businesses/businessTypes'
    const params = {
      filters: { ...filtersService.getActiveFilters(filters) },
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async businessesSummary(zoneEndpoint) {
    const endpoint = 'businesses/summary'

    const params = {
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async businesses(currentZone, filters = {}, from = 0) {
    const endpoint = 'businesses'

    const params = {
      ...filtersService.getActiveFilters(filters),
      from,
      size: batchSize,
      zone: currentZone?.name,
      zoneEndpoint: currentZone.businessMapperIndices,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      const { businesses, total } = data

      return {
        businesses,
        total,
      }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async businessReview(businessId, from, filters, zoneEndpoint) {
    const endpoint = 'business/reviews'

    if (!businessId) return {}

    const params = {
      businessId,
      from,
      filters,
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      const { reviews } = data

      return {
        reviews,
      }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async businessesExport(currentZone, filters = {}, scroll = undefined) {
    const endpoint = 'businesses/export'

    const params = {
      ...Object.fromEntries(Object.entries(filters).filter(([key, value]) => !!value)),
      scroll_id: scroll,
      zone: currentZone.name,
      zoneEndpoint: currentZone.businessMapperIndices,
    }

    const config = {
      params,
    }
    try {
      const { data } = await axios.get(endpoint, config)
      const { businesses, scrollId } = data

      return {
        businesses,
        scrollId,
      }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async business(currentZone, businessId) {
    const endpoint = 'business'

    const params = {
      businessId,
      zone: currentZone?.name,
      zoneEndpoint: currentZone.businessMapperIndices,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async businessRequests() {
    const endpoint = 'businessRequests'

    try {
      const { data } = await axios.get(endpoint)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async submitBusinessRequest(businessRequest) {
    const endpoint = 'businessRequests'

    const isCreate = !businessRequest.id
    const method = isCreate ? 'post' : 'put'

    try {
      const { data } = await axios[method](endpoint, businessRequest)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async deleteBusinessRequest(organizationName, id) {
    const endpoint = 'businessRequests'

    const config = {
      params: {
        organizationName,
        id,
      },
    }

    try {
      const { data } = await axios.delete(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async rentalsHostNames() {
    const endpoint = 'rentals/host-names'

    try {
      const { data } = await axios.get(endpoint)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async rentalsListingsPerHostAggregations(zoneEndpoint) {
    const endpoint = 'rentals/listings-per-host'

    const params = {
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async getMapClusterAggregation(currentZone, top_left, bottom_right, precision, hostFilters) {
    const endpoint = 'rentals/map-cluster-aggregate'

    const params = {
      ...Object.fromEntries(Object.entries(hostFilters).filter(([key, value]) => !!value)),
      top_left,
      bottom_right,
      precision,
      zone: currentZone?.name,
      zoneEndpoint: currentZone?.airbnbIndices,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async rentalsHostsWithMostListings(currentZone, filters = {}) {
    const endpoint = 'rentals/hosts-with-most-listings'

    const params = {
      ...Object.fromEntries(Object.entries(filters).filter(([key, value]) => !!value)),
      zone: currentZone?.name,
      zoneEndpoint: currentZone?.airbnbIndices,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async rentalsSummary(currentZone) {
    const endpoint = 'rentals/summary'

    const params = {
      zoneEndpoint: currentZone?.airbnbIndices,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async rentalsHosts(currentZone, filters = {}, from = 0) {
    const endpoint = 'rentals/hosts'
    const params = {
      ...Object.fromEntries(Object.entries(filters).filter(([key, value]) => !!value)),
      from,
      size: 50,
      zone: currentZone?.name,
      zoneEndpoint: currentZone?.airbnbIndices,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)

      const { totalHosts, hosts } = data

      return {
        totalHosts,
        hosts,
      }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async exportHosts(currentZone, filters) {
    const endpoint = 'rentals/rentalsListExport'
    const params = {
      ...Object.fromEntries(Object.entries(filters).filter(([key, value]) => !!value)),
      zoneEndpoint: currentZone?.airbnbIndices,
    }
    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async rentalsListings(currentZone, filters = {}, from = 0, size = batchSize) {
    const endpoint = 'rentals/listings'

    const params = {
      ...Object.fromEntries(Object.entries(filters).filter(([key, value]) => !!value)),
      from,
      size,
      zone: currentZone?.name,
      zoneEndpoint: currentZone?.airbnbIndices,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)

      const { totalListings, listings } = data

      return {
        totalListings,
        listings,
      }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async selectedHostsListings(hostId, from, zoneEndpoint) {
    const endpoint = 'rentals/selectedHostListings'

    if (!hostId) return {}

    const params = {
      hostId,
      from,
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      const { listings } = data

      return {
        listings,
      }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async hostsAndListingsLocations(zoneEndpoint) {
    const endpoint = 'rentals/hostsAndListingsLocations'

    const params = {
      zoneEndpoint,
      size: 1,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  // NFT
  async nftEntities(currentZone, from, filters = {}) {
    const endpoint = 'nftMapper'
    const MOMENT_FORMAT = 'YYYY/MM/DD'

    // if (activeFilters?.firstActivity) {
    //   const formattedFirstActivity = {
    //     start: moment(activeFilters.firstActivity.start).format(MOMENT_FORMAT),
    //     end: moment(activeFilters.firstActivity.end).format(MOMENT_FORMAT)
    //   };

    //   activeFilters.firstActivity = formattedFirstActivity;
    // }

    const params = {
      filters: filtersService.getActiveFilters(filters),
      from,
      size: batchSize,
      zone: currentZone?.name,
      zoneEndpoint: currentZone.nftIndices,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      const { entities, total, summary } = data

      return {
        entities,
        total,
        summary,
      }
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async nftEntitiesFilterOptions(currentZone, filters) {
    const endpoint = 'nftMapper/filterOptions'

    const params = {
      filters,
      zoneEndpoint: currentZone.nftIndices,
      zone: currentZone.name,
    }

    const config = { params }

    try {
      const { data: filterOptions } = await axios.get(endpoint, config)
      return filterOptions
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async nftEntity(entityId, zoneEndpoint) {
    const endpoint = 'nft'

    if (!entityId) return

    const params = {
      entityId,
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async nftEntityExport(entityId, zoneEndpoint) {
    const endpoint = 'nft/entityExport'

    if (!entityId) return

    const params = {
      entityId,
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async nftPeriodOverview(entityId, period, zoneEndpoint) {
    const endpoint = 'nft/periodOverview'

    if (!entityId) return

    const params = {
      entityId,
      period,
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async nftDocumentedActivity(entityId, period, filters, from, zoneEndpoint) {
    const endpoint = 'nft/documentedActivity'

    if (!entityId) return

    const params = {
      entityId,
      period,
      filters: Object.fromEntries(Object.entries(filters).filter(([key, value]) => !!value)),
      from,
      size: 10,
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (err) {
      this.handleApiError(err)
    }
  }

  async nftActivityExport(entityId, filters, activityEndpoint, period) {
    const endpoint = 'nft/activityExport'

    if (!entityId || !activityEndpoint || !period) return

    const params = {
      entityId,
      period,
      filters: Object.fromEntries(Object.entries(filters).filter(([key, value]) => !!value)),
      zoneEndpoint: activityEndpoint,
      size: batchSize,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async fetchGraphData(entityId, zoneEndpoint) {
    if (!entityId || !zoneEndpoint) return

    const endpoint = 'graph'

    const params = {
      entityId,
      zoneEndpoint,
    }

    const config = {
      params,
    }

    try {
      const { data } = await axios.get(endpoint, config)
      return data
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async getTotalDocumentsCount(index) {
    if (!index) return
    const endpoint = 'general/countDocs'
    const params = {
      index,
    }
    const config = { params }
    try {
      const { data: totalDocCount } = await axios.get(endpoint, config)
      return totalDocCount
    } catch (error) {
      this.handleApiError(error)
    }
  }
}

export default new ApiService()
