import { defineMessages } from 'react-intl'

const scope = 'app.components.cdtfaMessages'

export default defineMessages({
  siteName: {
    id: `${scope}.siteName`,
    defaultMessage: 'SITE NAME',
  },
  siteId: {
    id: `${scope}.siteId`,
    defaultMessage: 'SITE ID',
  },
  accountName: {
    id: `${scope}.accountName`,
    defaultMessage: 'ACCOUNT NAME',
  },
  accountId: {
    id: `${scope}.accountId`,
    defaultMessage: 'ACCOUNT ID',
  },
  customerName: {
    id: `${scope}.customerName`,
    defaultMessage: 'CUSTOMER NAME',
  },
  businessType: {
    id: `${scope}.businessType`,
    defaultMessage: 'BUSINESS TYPE',
  },
  naicsCdtfa: {
    id: `${scope}.naicsCdtfa`,
    defaultMessage: 'CDTFA NAICS',
  },
  naicsIvix: {
    id: `${scope}.naicsIvix`,
    defaultMessage: 'IVIX NAICS',
  },
  qtrYieldIvix: {
    id: `${scope}.adjustedTaxIvix`,
    defaultMessage: 'TOTAL TAX ADJUSTMENT IVIX (ACCOUNT)',
  },
  salesTaxesDue: {
    id: `${scope}.salesTaxesDue`,
    defaultMessage: 'TOTAL TAX DUE (ACCOUNT)',
  },
  webSources: {
    id: `${scope}.webSources`,
    defaultMessage: 'WEB SOURCES',
  },
  firstWebActivity: {
    id: `${scope}.firstWebActivity`,
    defaultMessage: 'FIRST WEB ACTIVITY',
  },
  //OVERVIEW
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Total',
  },
  withWebPresence: {
    id: `${scope}.withWebPresence`,
    defaultMessage: 'With Web Presence',
  },
  updatedNaics: {
    id: `${scope}.updatedNaics`,
    defaultMessage: 'Updated NAICS',
  },
  underReportedAccounts: {
    id: `${scope}.underReportedAccounts`,
    defaultMessage: 'Under Reporting',
  },
  totalAdjustedTax: {
    id: `${scope}.totalAdjustedTax`,
    defaultMessage: 'Total Tax Adjustment',
  },
})
