import React, { FC } from 'react'
import { selectCurrentZone } from '../../store/global'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import BusinessesSummary from 'components/BusinessesSummary/BusinessesSummary'
import InfluencersTable from 'components/influencers/InfluencersTable/InfluencersTable'
import GridLoader from 'components/GridLoader/GridLoader'
import TableActionsBars from 'components/verticals/TableActionsBar/TableActionsBar'

import { BaseTableOverview, CurrentZone } from 'utils/types'
import { InfluencersEntityForTable } from 'containers/InfluencersTablePage/types'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import cx from 'classnames'

import messages from './messages'
import styles from './InfluencersTablePage.scss'
import { useVerticalOverviewQuery } from 'hooks/useVerticalOverviewQuery'
import { useVerticalEntitiesQuery } from 'hooks/useVerticalEntitiesQuery'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'
import { getUrlPageValues } from '../VerticalPage/common/utils'

const csvFileName = 'Influencers'

const InfluencersTablePage: FC = () => {
  const { searchValue, searchValueDebounced, sorting, setSearchValue, setSorting } = useVerticalSearchAndSort()

  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  // Fetch overview data
  const { data: verticalOverviewData, isLoading: isLoadingOverview } = useVerticalOverviewQuery<BaseTableOverview>({
    verticalIndices: currentZone?.influencersIndices,
    currentQueryKey: 'influencers-overview-key',
    searchValue: searchValueDebounced,
  })

  // Fetch entities data
  const { verticalEntities, fetchNextPage, isFetching, isLoading } =
    useVerticalEntitiesQuery<InfluencersEntityForTable>({
      verticalIndices: currentZone?.influencersIndices,
      currentQueryKey: 'influencers-entities-key',
      searchValue: searchValueDebounced,
      sorting,
    })

  return (
    <div className={styles.container}>
      <nav className={styles.navigationHeaderWrapper}>
        <NavigationHeader />
      </nav>
      <header className='page-header withNavigationHeader'>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <section className={styles.summary}>
            <BusinessesSummary
              isLoading={isLoadingOverview}
              summary={{
                entityCounts: verticalOverviewData?.summary.entitiesCount,
                incomeCounts: verticalOverviewData?.summary.incomeCounts,
                topIndustries: verticalOverviewData?.summary.platforms.map(({ source, count }) => [source, count]),
                industriesCount: verticalOverviewData?.summary.platforms.reduce((acc, { count }) => acc + count, 0),
              }}
              isInfluencers
            />
          </section>
          <div className={cx(styles.content, { [styles.withFilters]: false })}>
            <div className={styles.gridContainer}>
              <TableActionsBars
                countMessages={messages.count}
                displayedCount={verticalOverviewData ? verticalEntities?.length ?? null : null}
                totalCount={verticalOverviewData && verticalEntities?.length ? verticalOverviewData.total : null}
                zoneEndpoint={currentZone?.influencersIndices}
                csvFileName={csvFileName}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                type={getUrlPageValues()[0]}
              />
              {isLoading ? (
                <GridLoader />
              ) : (
                <section className={styles.grid}>
                  <InfluencersTable
                    isFetching={isFetching}
                    fetchNextPage={fetchNextPage}
                    totalFetched={verticalEntities?.length || 0}
                    totalDBRowCount={verticalEntities?.length ? verticalOverviewData?.total ?? 0 : 0}
                    verticalEntities={verticalEntities}
                    sorting={sorting}
                    setSorting={setSorting}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default InfluencersTablePage
