export enum FilterTypes {
  ROBUST_RANGE = 'robustRange',
  RANGE = 'range',
  BOOL = 'bool',
  CHIPS = 'chips',
}

export interface Filter {
  name: string
  label: string
  value: string[]
  type?: FilterTypes
  activeFilterLabel?: string
  minLimit?: number
  maxLimit?: number
  step?: number
}

export interface FilterAdditionalData {
  name: string
  [key: string]: string | number
}
