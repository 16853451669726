import React, { useState, useMemo } from 'react'
import { FormGroup, Label, Button } from 'reactstrap'
import Select, { MultiValue } from 'react-select'
import GenericDrawer from 'components/GenericDrawer/GenericDrawer'
import styles from './FilterDrawer.module.scss'
import { Filter, FilterAdditionalData, FilterTypes } from './FilterDrawer.config'
import ActiveFilters from 'components/ActiveFilters/ActiveFilters'
import Range from 'UI/Range/Range'
import Toggle from 'UI/Toggle/Toggle'
import { getSplitFilterMapping } from 'components/ActiveFilters/utils'

interface FilterDrawerProps {
  open: boolean
  onClose: () => void
  activeFilters: Filter[]
  setActiveFilters: React.Dispatch<React.SetStateAction<Filter[]>>
  filtersInitialState: Filter[]
  filterOptions: Record<string, { value: string; label: string }[]>
  filterAdditionalData?: FilterAdditionalData[]
}

const FilterDrawer = ({
  open,
  onClose,
  activeFilters,
  setActiveFilters,
  filtersInitialState,
  filterOptions,
  filterAdditionalData,
}: FilterDrawerProps) => {
  const [filters, setFilters] = useState<Filter[]>(filtersInitialState)
  const [isApplyFilterDisabled, setIsApplyFilterDisabled] = useState(true)

  const handleApplyFilter = () => {
    setActiveFilters(filters.filter(filter => filter?.value.length > 0))
  }

  const handleFilterChange = (
    name: string,
    selectedOptions: MultiValue<{
      value: string
      label: string
    }>,
  ) => {
    const updatedFilters = filters.map(filter =>
      filter.name === name ? { ...filter, value: selectedOptions.map(option => option.value) } : filter,
    )

    setFilters(updatedFilters)
    updateApplyFilterButtonState(updatedFilters)
  }

  const handleRangeFilterChange = (name: string, type: 'min' | 'max', value: string) => {
    const updatedFilters = filters.map(filter => {
      const newValue = [...filter.value]
      const index = type === 'min' ? 0 : 1
      newValue[index] = value
      return filter.name === name ? { ...filter, value: newValue } : filter
    })

    setFilters(updatedFilters)
    updateApplyFilterButtonState(updatedFilters)
  }

  const handleBooleanFilterChange = (name: string, value: boolean) => {
    const updatedFilters = filters.map(filter => {
      const newValue = [...filter.value]
      newValue[0] = value ? 'true' : 'false'
      return filter.name === name ? { ...filter, value: newValue } : filter
    })

    setFilters(updatedFilters)
    updateApplyFilterButtonState(updatedFilters)
  }

  const updateApplyFilterButtonState = (updatedFilters: Filter[]) => {
    const isDisabled = updatedFilters.every(filter => filter.value.length === 0)
    setIsApplyFilterDisabled(isDisabled)
  }

  const displayedFilters = useMemo(() => {
    const { rangeFilters, booleanFilters, defaultFilters } = getSplitFilterMapping(activeFilters)

    return [...rangeFilters, ...booleanFilters, ...defaultFilters]
  }, [activeFilters])

  const clearFilters = () => {
    setFilters(filtersInitialState)
    setActiveFilters([])
  }

  return (
    <GenericDrawer
      open={open}
      onClose={onClose}
      title='Filters'
      iconName='filterBlue'
      optionalAction={clearFilters}
      optionalActionLabel='Clear All'
    >
      <div className={styles.filterDrawerContainer}>
        <div className={styles.mainContent}>
          {displayedFilters.length > 0 && (
            <div className={styles.activeFilters}>
              <p>Active Filters:</p>
              <ActiveFilters
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                styles={styles}
                filterAdditionalData={filterAdditionalData}
                filterOptions={filterOptions}
                setFilters={setFilters}
              />
            </div>
          )}
          {filters.map((filter, index) => {
            if (filter.type === FilterTypes.ROBUST_RANGE) {
              const additionalData = filterAdditionalData?.find(el => el.name === filter.name)

              return (
                <Range
                  rangeType={FilterTypes.ROBUST_RANGE}
                  title={filter.label}
                  minLabel='Min. Tax'
                  maxLabel='Max. Tax'
                  minValue={filter.value?.[0]}
                  maxValue={filter.value?.[1]}
                  onMinChange={value => handleRangeFilterChange(filter.name, 'min', value)}
                  onMaxChange={value => handleRangeFilterChange(filter.name, 'max', value)}
                  key={index}
                  minLimit={typeof additionalData?.minLimit === 'number' ? additionalData.minLimit : undefined}
                  maxLimit={typeof additionalData?.maxLimit === 'number' ? additionalData.maxLimit : undefined}
                />
              )
            }
            if (filter.type === FilterTypes.RANGE) {
              const minValue = filterOptions[filter?.name][0]?.value[0] || 0
              const maxValue = filterOptions[filter?.name][0]?.value[1] || 0
              const marks = Array.from({ length: +maxValue + 1 }, (_, index) => ({
                value: index,
                label: index.toString(),
              }))
              return (
                <Range
                  rangeType={FilterTypes.RANGE}
                  title={filter.label}
                  minLimit={minValue as number}
                  maxLimit={maxValue as number}
                  minValue={filter.value?.[0]}
                  maxValue={filter.value?.[1]}
                  onMinChange={value => handleRangeFilterChange(filter.name, 'min', value)}
                  onMaxChange={value => handleRangeFilterChange(filter.name, 'max', value)}
                  key={index}
                  step={filter.step}
                  marks={marks}
                />
              )
            }
            if (filter.type === FilterTypes.BOOL) {
              return (
                <div key={index} className={styles.boolTypeContainer}>
                  <div className={styles.boolLabel}>{filter.label}</div>
                  <Toggle
                    checked={filter.value?.[0] === 'true'}
                    key={index}
                    onChange={e => handleBooleanFilterChange(filter.name, e.target.checked)}
                  />
                </div>
              )
            }
            return (
              <FormGroup key={filter?.name} className={styles.formGroup}>
                <Label for={filter?.name}>{filter?.label}</Label>
                <Select
                  className={styles.selectInput}
                  isMulti
                  options={filterOptions[filter?.name]}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={(filter?.value || []).map(value => ({ value, label: value }))}
                  onChange={selectedOptions => handleFilterChange(filter?.name, selectedOptions)}
                />
              </FormGroup>
            )
          })}
        </div>

        <Button
          className={styles.applyButton}
          color='primary'
          onClick={() => {
            handleApplyFilter()
            onClose()
          }}
          disabled={isApplyFilterDisabled}
        >
          Apply Filter
        </Button>
      </div>
    </GenericDrawer>
  )
}

export default FilterDrawer
